import { FaLinkedin } from "react-icons/fa";
import { MdMail as FaVoicemail } from "react-icons/md";

import './style.css';

type PropType = {
  title: string,
  text: string,
  text2?: string,
  linkedin?: string,
  email?: string
}

const JdbServices = ({ title, text, text2, linkedin, email }: PropType) => {

  const handleLinkedinClick = () => {
    if (linkedin) {
      window.open(linkedin, '_blank');
    }
  };

  const handleEmailClick = () => {
    if (email) {
      window.open(`mailto:${email}`);
    }
  };

  return (
    <div className="jdbservices__container__feature">
      <div className="jdbservices__container__feature-title">
        <div />
        <h1>{title}</h1>
      </div>
      <div className="jdbservices__container__feature-text">
        <p>{text}</p>
        {text2 ? <p>{text2}</p> : ""}
      </div>
      <div className="jdbservices__container__feature-icons">
        {linkedin && <FaLinkedin size="28" color="white" onClick={handleLinkedinClick} className="icon_contact"/>}
        {email && <FaVoicemail size="31" color="white" onClick={handleEmailClick} className="icon_contact"/>}
      </div>
    </div>
  );
};

export default JdbServices;
