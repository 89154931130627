import { Header, Footer, Blog, OurServices, Possibilities,WhoAreWe } from "./pages"
import { CTA,Navbar} from "./components"
import "./App.css"
import ArrowToTop from "./components/ArrowToTop/ArrowToTop"
const App = () => {
  return (
    <div>
      <div className="gradient__bg">
        <Navbar/>
        <Header/>
      </div>
    <WhoAreWe />
    <OurServices />
    <Possibilities />
    <CTA />
    <Blog />
    <Footer />
    <ArrowToTop/>
    </div>
  )
}

export default App

