export const sendEmail = () => {
    const recipients = ["contact@jdbinfotech.in"];
    const ccRecipients = ["sagar@jdbinfotech.in", "akshay@jdbinfotech.in","dhairya@jdbinfotech.in"]; // Add the recipients to be CCed
    const subject = "Early Access Request";
    const body = "Hi! I would like to request early access to get started with ClickCop.";
  
    const cc = ccRecipients.join(",");
   
    const emailLink = `mailto:${recipients.join(",")}?cc=${encodeURIComponent(cc)}&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    
    window.open(emailLink);
};
