import React from 'react';
import './style.css';
import { JdbServices } from '../../components';

const WhoAreWe = () => (
  <div className="who_are_we section__margin" id="whoarewe">
    <div className="who_are_we__feature">
      <JdbServices title="Who Are We" text=" A beacon of digital security innovation. Our mission is to revolutionize cybersecurity by offering seamless protection against online threats. With ByePhisher, rest assured that your digital interactions are safeguarded, ensuring peace of mind in an ever-evolving digital landscape" />
    </div>
    <div className="who_are_we__heading">
      <h1 className="gradient__text">Unlock the Unimaginable: Elevating Cybersecurity Beyond Boundaries</h1>
      {/* <p>Explore the Library</p> */}
    </div>
    <div className="who_are_we__container">
      <JdbServices title="Advanced Phishing Defense with ByePhisher" text="Utilizing cutting-edge AI/ML models, ByePhisher revolutionizes cybersecurity by deploying a Safe Login Algorithm. Our solution acts as a shield against social attacks, mitigating the risk of sensitive data exposure and financial losses. With ByePhisher's isolation box and sophisticated blacklist/whitelist DNS capabilities, companies can fortify their defenses against email phishing attempts and fake website infiltrations." />
      <JdbServices title="Seamless Integration, Maximum Security" text=" Our automated phishing detection system seamlessly integrates into users' online activities, requiring minimal user interaction. This ensures hassle-free protection against email phishing and fake websites, allowing employees to navigate the digital landscape with confidence. Through our subscription-based model, companies gain access to advanced cybersecurity features at an affordable cost, safeguarding their data and maintaining trust in digital interactions." />
      <JdbServices title="Elevated Cybersecurity Standards" text="We set new benchmarks in cybersecurity, offering comprehensive protection against social attacks and unauthorized access attempts. Our solution empowers companies to proactively defend against data breaches, email phishing, and unsafe logins. By leveraging AI/ML models and robust isolation mechanisms, ByePhisher ensures that employees can securely access online resources without compromising sensitive information or falling victim to malicious actors." />
    </div>
  </div>
);

export default WhoAreWe;
