
import  FooterJdbService   from './FooterService';
import "./style.css";

const Footer = () => (
 
  <div className="footer who_are_we" id="contactUs">
    {/**<div className="who_are_we__feature">
      <JdbServices title="Who Are We" text=" A beacon of digital security innovation. Our mission is to revolutionize cybersecurity by offering seamless protection against online threats. With ByePhisher, rest assured that your digital interactions are safeguarded, ensuring peace of mind in an ever-evolving digital landscape" />
    </div>**/}
     <div className="who_are_we__heading">
      <h1 className="gradient__text">Meet our Founders</h1>
    </div> 
    <div className="who_are_we__container">
      
      <FooterJdbService title="Sagar Singh" text="Co-Founder"  linkedin="https://www.linkedin.com/in/sagarsinghbhati?" email="sagar@jdbinfotech.in"/>
      <FooterJdbService title="Dhairya Joshi" text="Co-Founder" linkedin="https://www.linkedin.com/in/dhairyajoshi-" email="dhairya@jdbinfotech.in"/>
      <FooterJdbService title="Akshay Dabas" text="Co-Founder" 
      linkedin="https://www.linkedin.com/in/AkshayDabas" email="akshay@jdbinfotech.in"/>
    </div>
  </div>
  
);

export default Footer;