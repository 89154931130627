import React from 'react';
import './style.css';
import { sendEmail } from '../../utils/sendmail';

const CTA = () => (
  <div className="cta">
    <div className="cta__content">
      <p onClick={()=> sendEmail()}>Request Early Access to Get Started</p>
      <h3>Register Today & start safe browsing.</h3>
    </div>
    <div className="cta__btn">
      <button type="button" onClick={()=>sendEmail()}>Know our plans</button>
    </div>
  </div>
);

export default CTA;