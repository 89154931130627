import { useState, useEffect } from "react";
import { FaArrowUp } from "react-icons/fa";
import "./style.css";

const ArrowToTop = () => {
  const [showArrow, setShowArrow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 100) {
        setShowArrow(true);
      } else {
        setShowArrow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <div className={`arrowTop ${showArrow ? "show" : ""}`} onClick={scrollToTop}>
      {showArrow && <FaArrowUp size={40} color="white" />}
    </div>
  );
};

export default ArrowToTop;
