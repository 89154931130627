import { SetStateAction, useState } from 'react';
import { ai } from '../../assets'; // Removed 'people' import since it is not used
import './style.css';
import { sendEmail } from '../../utils/sendmail';

const Header = () => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleGetStarted = () => {
    if (!validateEmail(email)) {
      setErrorMessage('Please enter a valid email.');
      return;
    }
    sendEmail();
  };

  const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleEmailChange = (e: { target: { value: SetStateAction<string>; }; }) => {
    setEmail(e.target.value);
    setErrorMessage(''); // Clear error message when email changes
  };

  return (
    <div className="header section__padding" id="home">
      <div className="header__content">
        <h1 className="gradient__text">Shielding you from digital threats, one encrypted layer at a time</h1>
        <p>Protecting your data, shielding against malicious content, and thwarting website spoofing—our cyber security platform offers comprehensive defense against online threats. With vigilant monitoring and proactive measures, your digital interactions remain safe and secure.</p>

        <div className="header__content__input">
          <input
            type="email"
            placeholder="Your Email Address"
            value={email}
            onChange={handleEmailChange}
          />
          <button type="button" onClick={handleGetStarted}>Contact Us</button>
        </div>

        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>

      <div className="header__image">
        <img src={ai} alt="AI Icon" />
      </div>
    </div>
  );
};

export default Header;
