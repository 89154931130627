import React from 'react';
import './style.css';
type articleProps={
  imgUrl: string,
  date: string,
  text: string
}
const Article = ({ imgUrl, date, text }: articleProps) => (
  <div className="blog__container__article">
    <div className="blog__container__article_image">
      <img src={imgUrl} alt="blog__image" />
    </div>
    <div className="blog__container__article__content">
      <div>
        <p>{date}</p>
        <h4>{text}</h4>
      </div>
      {/* <p>Read Full Article</p> */}
    </div>
  </div>
);

export default Article;