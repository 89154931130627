import React from 'react';
import possibilityImage from '../../assets/possibility.png';
import './style.css';

const Possibilities = () => (
  <div className="possibility section__padding" id="possibility">
    <div className="possibility__image">
      <img src={possibilityImage} alt="possibility" />
    </div>
    <div className="possibility__content">
      {/* <h4>Request Early Access to Get Started</h4> */}
      <h1 className="gradient__text">Industry Leaders Recognise Us <br />Embracing the New Era of Protection</h1>
    
     
      <p>Our Reputation speaks for itself.

We've earned the recognition esteemed companies in India, including Geeks for Geeks, Supra Technologies, TCS, Master’s Union, and many more. With positive feedback and excitement from each evaluation, our commitment to excellence is recognized and celebrated. Join the ranks of industry leaders who trust our solutions for top-notch cybersecurity."</p>
{/* <h4>Industry Leaders Trust Us</h4> */}
    </div>
  </div>
);

export default Possibilities;