import React from 'react';
import './style.css';
import { Article } from '../../components';
import { blog1,blog2,blog3,blog4,blog5 } from '../../assets';
const Blog = () => (
  <div className="blog section__padding" id="blog">
    <div className="blog-heading">
      <p className="gradient__text" id="para">In today's digital world, Social attacks are a huge problem. They trick people into
giving away sensitive information, leading to big financial losses billions of dollars each year.</p>
    </div>
    <div className="blog-container">
      <div className="blog-container_groupA">
        <Article imgUrl={blog1} date="Financial threats" text="Last year, 1.13 million cases
had an amount involved of
Rs 7,488.6 crore were
registered for phishing
attacks. 
Now is the time to delve into the repercussions of phishing attacks on organizations. Take this opportunity to ensure you're maximizing your defense strategy and fortifying your cybersecurity measures." />
      </div>
      <div className="blog-container_groupB">
        <Article imgUrl={blog2} date="Phishy Spam Emails" text="3.4 billion emails a day are
sent by cyber criminals. Between 2013 and 2015, Facebook and Google, two of the world's biggest tech firms were duped out of $100 million after falling victim to a fake invoice scam.
" />
        <Article imgUrl={blog3} date="Anyone can be targetted" text="Safe to say cybercriminals have been really putting in the hours in the past few years. But it’s worth their while, because they’re getting valuable data. " />
        <Article imgUrl={blog4} date="Data is fuel, Data is money" text="Total number of attacks
almost doubled from
2021 to 2023. 84% of organizations were
the targets of at least one
phishing attempt in 2022
" />
        <Article imgUrl={blog5} date="One phishing attack can cause disaster across your organization" text="Research by Deloitte
found that 91% of all
cyberattacks begin with
a phishing attack." />
      </div>
    </div>
  </div>
);

export default Blog;