import React, { useState } from "react";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import { FaLinkedin } from "react-icons/fa6";
import "./style.css";
import { jdblogo } from "../../assets";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const scrollToSection = (id : string) => {
    const element = document.getElementById(id);
    // const navscroll= document.getElementsByClassName("navbar");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleLinkedinClick = () => {
  
      window.open("https://www.linkedin.com/company/jdbinfotech", '_blank');
    
  };
  return (
    <div className="navbar">
      <div className="navbar__links">
        {/* <div className="navbar__links__logo"> */}
          {/* < AiOutlineSafety height="22px" width="22px" />
          <p>{process.env.REACT_APP_COMPANY_NAME}</p> */}
          <img src={jdblogo} alt="Company Logo" height="60px"/>
        {/* </div> */}
        <div className="navbar__links__container">
          <p onClick={() => scrollToSection("home")}>Home</p>
          <p onClick={() => scrollToSection("whoarewe")}>Who Are We?</p>
          <p onClick={() => scrollToSection("possibility")}>Why trust us?</p>
          <p onClick={() => scrollToSection("features")}>Our Services</p>
          <p onClick={() => scrollToSection("blog")}>Read about security</p>
          <p onClick={() => scrollToSection("contactUs")}>Contact Us</p>
        </div>
      </div>
      <div className="navbar__sign">
        {/* <p>Sign in</p>
        <button type="button">Sign up</button> */}
         <FaLinkedin size="40"  onClick={handleLinkedinClick} style={{cursor:"pointer",color:"#031B34"}}  className="company__linkedin"/>
      </div>
      <div className="navbar__menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="navbar__menu__container scale-up-center">
            <div className="navbar__menu__container__links">
              <p onClick={() => scrollToSection("home")}>Home</p>
              <p onClick={() => scrollToSection("whoarewe")}>Who Are We?</p>
              <p onClick={() => scrollToSection("possibility")}>Why trust us?</p>
              <p onClick={() => scrollToSection("features")}>Our Services</p>
              <p onClick={() => scrollToSection("blog")}>Read about security</p>
              <p onClick={() => scrollToSection("contactUs")}>Contact Us</p>
            </div>
            <div className="navbar__menu__container__links__sign">
              {/* <p>Sign in</p>
              <button type="button">Sign up</button> */}
            </div>
          </div>
        )}
      </div>
   
    </div>
  );
};

export default Navbar;
