import './style.css';
import { JdbServices } from '../../components';
import { sendEmail } from '../../utils/sendmail';

const featuresData = [
  {
    title: 'Proactive Threat Defense',
    text: 'Stay ahead of cyber threats with advanced algorithms and AI integration. Our solutions safeguard against email threats, phishing links, and fraudulent websites, ensuring a secure digital environment.',
  },
  {
    title: 'Data Leak Prevention',
    text: 'Ensure the security of your company\'s digital assets with robust cybersecurity measures. We safeguard against phishing attempts, fraudulent websites, and unauthorized access, maintaining trust and integrity within your organization.',
  },
  {
    title: 'Safe Click Assurance',
    text: 'Empower your employees with Safe Click Assurance. Our advanced cybersecurity solutions ensure every click is shielded from potential threats, safeguarding your company\'s digital infrastructure.',
  },
  {
    title: 'Real-Time Threat Tracking',
    text: 'Monitor malicious website visits during work hours in real-time. Our comprehensive system provides insights into potential risks, allowing proactive mitigation measures and ensuring a secure browsing environment for your employees.',
  },
  {
    title: 'Effortless Trust Building',
    text: 'Experience immediate trust enhancement with our innovative solutions. Our platform ensures rapid improvement in trust levels, garnering applause from households.',
  },
  {
    title: 'Active Engagement Enablement',
    text: 'Enable active engagement effortlessly with our intuitive features. Our platform facilitates active participation, enabling seamless interaction and assistance.',
  },
];



const OurServices = () => (
  <div className="OurServices section__padding" id="features">
    <div className="OurServices__heading">
      <h1 className="gradient__text">Empower Your Company's Future with Cutting-Edge Cybersecurity Solutions. Step into Tomorrow's Security Standards Today & Make It Happen</h1>
      <p className="get__started" onClick={sendEmail}>Request Early Access to Get Started</p>
    </div>
    <div className="OurServices__container">
      {featuresData.map((item, index) => (
        <JdbServices title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default OurServices;
